import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { Button } from './ui/button';
import { Grid2X2 } from 'lucide-react';
import { categories } from '@/data/categories';

export function CategoryDialog() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm" className="gap-2">
          <Grid2X2 className="h-4 w-4" />
          Categories
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Browse Service Categories</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {categories.map((mainCategory) => (
            <div key={mainCategory.id}>
              <h3 className="mb-2 text-lg font-semibold">{mainCategory.name}</h3>
              <div className="grid grid-cols-2 gap-2">
                {mainCategory.subcategories?.map((subcategory) => (
                  <Button
                    key={subcategory.id}
                    variant="ghost"
                    className="justify-start"
                    onClick={() => {
                      navigate(`/category/${subcategory.id}`);
                      setOpen(false);
                    }}
                  >
                    {subcategory.name}
                  </Button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}