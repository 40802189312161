import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Briefcase } from 'lucide-react';

interface Experience {
  id: string;
  role: string;
  company: string;
  period: string;
  description: string;
}

interface ExperienceTimelineProps {
  experiences: Experience[];
}

export function ExperienceTimeline({ experiences }: ExperienceTimelineProps) {
  return (
    <Card className="mb-8">
      <CardHeader>
        <CardTitle>Professional Experience</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="relative">
          {/* Timeline line */}
          <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-gray-200" />

          {/* Experience items */}
          <div className="space-y-8">
            {experiences.map((experience) => (
              <div key={experience.id} className="relative pl-12">
                {/* Timeline dot */}
                <div className="absolute left-2.5 -translate-x-1/2 w-3 h-3 bg-blue-600 rounded-full border-2 border-white" />
                
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="flex items-center mb-2">
                    <Briefcase className="w-4 h-4 text-gray-500 mr-2" />
                    <h3 className="font-medium text-gray-900">{experience.role}</h3>
                  </div>
                  <p className="text-sm text-gray-600 mb-1">{experience.company}</p>
                  <p className="text-sm text-gray-500 mb-2">{experience.period}</p>
                  <p className="text-gray-700">{experience.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}