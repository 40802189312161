import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { MetricsOverview } from '../components/admin/analytics/MetricsOverview';
import { UserDemographics } from '../components/admin/analytics/UserDemographics';
import { GrowthTrends } from '../components/admin/analytics/GrowthTrends';
import { UserManagement } from '../components/admin/UserManagement';
import { ReviewModeration } from '../components/admin/ReviewModeration';
import { Settings } from '../components/admin/Settings';
import { SEOHead } from '../components/SEOHead';
import { Button } from '../components/ui/button';
import { Download } from 'lucide-react';

export function AdminDashboard() {
  const handleExport = (format: 'csv' | 'pdf') => {
    // Implement export functionality
    console.log(`Exporting in ${format} format`);
  };

  return (
    <>
      <SEOHead
        title="Admin Dashboard"
        description="Admin dashboard for managing users, reviews, and system settings"
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Admin Dashboard</h1>
          <div className="flex gap-2">
            <Button variant="outline" onClick={() => handleExport('csv')}>
              <Download className="w-4 h-4 mr-2" />
              Export CSV
            </Button>
            <Button variant="outline" onClick={() => handleExport('pdf')}>
              <Download className="w-4 h-4 mr-2" />
              Export PDF
            </Button>
          </div>
        </div>

        <Tabs defaultValue="analytics" className="space-y-6">
          <TabsList>
            <TabsTrigger value="analytics">Analytics</TabsTrigger>
            <TabsTrigger value="users">Users</TabsTrigger>
            <TabsTrigger value="reviews">Reviews</TabsTrigger>
            <TabsTrigger value="settings">Settings</TabsTrigger>
          </TabsList>

          <TabsContent value="analytics" className="space-y-6">
            <MetricsOverview />
            <UserDemographics />
            <GrowthTrends />
          </TabsContent>

          <TabsContent value="users">
            <UserManagement />
          </TabsContent>

          <TabsContent value="reviews">
            <ReviewModeration />
          </TabsContent>

          <TabsContent value="settings">
            <Settings />
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
}