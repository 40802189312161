import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Users, Star, MessageSquare, TrendingUp } from 'lucide-react';
import { ReviewsChart } from './charts/ReviewsChart';
import { RatingDistribution } from './charts/RatingDistribution';
import { UserGrowth } from './charts/UserGrowth';

export function Analytics() {
  const stats = [
    {
      title: 'Total Users',
      value: '1,234',
      change: '+12%',
      icon: Users,
    },
    {
      title: 'Average Rating',
      value: '4.8',
      change: '+0.2',
      icon: Star,
    },
    {
      title: 'Reviews',
      value: '856',
      change: '+24%',
      icon: MessageSquare,
    },
    {
      title: 'Active Professionals',
      value: '432',
      change: '+8%',
      icon: TrendingUp,
    },
  ];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map((stat) => (
          <Card key={stat.title}>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                {stat.title}
              </CardTitle>
              <stat.icon className="h-4 w-4 text-gray-500" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{stat.value}</div>
              <p className="text-xs text-gray-600 mt-1">
                {stat.change} from last month
              </p>
            </CardContent>
          </Card>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <ReviewsChart />
        <UserGrowth />
      </div>

      <RatingDistribution />
    </div>
  );
}