import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { Search, Filter } from 'lucide-react';
import { ReviewTable } from './ReviewTable';
import { useToast } from '../ui/use-toast';

export function ReviewModeration() {
  const { toast } = useToast();
  const [searchQuery, setSearchQuery] = React.useState('');

  const handleApproveReview = async (reviewId: string) => {
    try {
      // API call to approve review
      toast({
        title: 'Review Approved',
        description: 'Review has been approved successfully.',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to approve review.',
        variant: 'destructive',
      });
    }
  };

  const handleRejectReview = async (reviewId: string) => {
    try {
      // API call to reject review
      toast({
        title: 'Review Rejected',
        description: 'Review has been rejected.',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to reject review.',
        variant: 'destructive',
      });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Review Moderation</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex gap-4 mb-6">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
            <Input
              placeholder="Search reviews..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-9"
            />
          </div>
          <Button variant="outline">
            <Filter className="w-4 h-4 mr-2" />
            Filters
          </Button>
        </div>

        <ReviewTable
          onApproveReview={handleApproveReview}
          onRejectReview={handleRejectReview}
        />
      </CardContent>
    </Card>
  );
}