import React from 'react';
import { LocationSearch } from './LocationSearch';
import { Wrench, Shield, Clock } from 'lucide-react';

interface HeroSectionProps {
  onLocationSelect: (location: string) => void;
}

export function HeroSection({ onLocationSelect }: HeroSectionProps) {
  return (
    <div className="relative bg-gradient-to-r from-blue-600 to-blue-800 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-grid-white opacity-10" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 sm:py-32">
        <div className="text-center max-w-3xl mx-auto">
          <h1 className="text-4xl font-extrabold text-white sm:text-5xl lg:text-6xl animate-fade-in">
            Find Trusted Local Professionals
          </h1>
          <p className="mt-6 text-xl text-blue-100 max-w-2xl mx-auto">
            Connect with qualified experts for all your home service needs
          </p>

          <div className="mt-10">
            <LocationSearch onLocationSelect={onLocationSelect} />
          </div>

          {/* Trust Indicators */}
          <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-3">
            {[
              {
                icon: Shield,
                title: 'Verified Pros',
                description: 'Background-checked and licensed'
              },
              {
                icon: Clock,
                title: 'Quick Response',
                description: 'Same-day service available'
              },
              {
                icon: Wrench,
                title: 'Quality Work',
                description: 'Satisfaction guaranteed'
              }
            ].map((feature) => (
              <div key={feature.title} className="flex flex-col items-center">
                <feature.icon className="h-8 w-8 text-blue-300" />
                <h3 className="mt-4 text-lg font-semibold text-white">
                  {feature.title}
                </h3>
                <p className="mt-2 text-sm text-blue-200">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}