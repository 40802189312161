import { useState, useEffect } from 'react';
import { Professional } from '../types';
import { dataService } from '../services/dataService';

export function useProfessionalDetails(professionalId: string | undefined) {
  const [professional, setProfessional] = useState<Professional | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProfessional = async () => {
      try {
        setLoading(true);
        if (!professionalId) {
          throw new Error('Professional ID is required');
        }

        const found = dataService.getProfessionalById(professionalId);
        
        if (found) {
          setProfessional(found);
          setError(null);
        } else {
          setError('Professional not found');
          setProfessional(null);
        }
      } catch (err) {
        setError('Failed to fetch professional details');
        setProfessional(null);
      } finally {
        setLoading(false);
      }
    };

    fetchProfessional();
  }, [professionalId]);

  return { professional, loading, error };
}