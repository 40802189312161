import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card';
import { Users, DollarSign, TrendingUp, Activity } from 'lucide-react';

const metrics = [
  {
    title: 'Active Users',
    stats: {
      daily: '2,845',
      weekly: '15,672',
      monthly: '45,290'
    },
    change: '+12.5%',
    icon: Users
  },
  {
    title: 'Revenue',
    stats: {
      daily: '$3,245',
      weekly: '$18,590',
      monthly: '$67,245'
    },
    change: '+23.1%',
    icon: DollarSign
  },
  {
    title: 'Conversion Rate',
    stats: {
      daily: '3.2%',
      weekly: '3.8%',
      monthly: '4.1%'
    },
    change: '+0.8%',
    icon: TrendingUp
  },
  {
    title: 'User Engagement',
    stats: {
      daily: '78%',
      weekly: '82%',
      monthly: '85%'
    },
    change: '+5.2%',
    icon: Activity
  }
];

export function MetricsOverview() {
  const [timeframe, setTimeframe] = React.useState<'daily' | 'weekly' | 'monthly'>('monthly');

  return (
    <div className="space-y-6">
      <div className="flex justify-end">
        <select
          value={timeframe}
          onChange={(e) => setTimeframe(e.target.value as 'daily' | 'weekly' | 'monthly')}
          className="rounded-md border border-gray-300 px-3 py-1"
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {metrics.map((metric) => (
          <Card key={metric.title}>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                {metric.title}
              </CardTitle>
              <metric.icon className="h-4 w-4 text-gray-500" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{metric.stats[timeframe]}</div>
              <p className="text-xs text-gray-600 mt-1">
                {metric.change} from previous {timeframe.slice(0, -2)}
              </p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}