import React from 'react';
import { Mail, Phone, Calendar, Linkedin, Twitter, Github } from 'lucide-react';
import { Button } from '../ui/button';
import { Professional } from '../../types';

interface ProfileHeaderProps {
  professional: Professional;
}

export function ProfileHeader({ professional }: ProfileHeaderProps) {
  const handleSocialClick = (url: string) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-8">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="w-48 h-48 flex-shrink-0">
          <img
            src={professional.image}
            alt={professional.name}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        
        <div className="flex-grow">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">{professional.name}</h1>
              <p className="text-lg text-gray-600">{professional.title}</p>
            </div>
            
            <div className="flex gap-2 mt-4 md:mt-0">
              {professional.socialMedia?.linkedin && (
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={() => handleSocialClick(professional.socialMedia?.linkedin || '')}
                >
                  <Linkedin className="w-4 h-4 mr-2" />
                  LinkedIn
                </Button>
              )}
              {professional.socialMedia?.twitter && (
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={() => handleSocialClick(professional.socialMedia?.twitter || '')}
                >
                  <Twitter className="w-4 h-4 mr-2" />
                  Twitter
                </Button>
              )}
              {professional.socialMedia?.github && (
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={() => handleSocialClick(professional.socialMedia?.github || '')}
                >
                  <Github className="w-4 h-4 mr-2" />
                  Github
                </Button>
              )}
            </div>
          </div>

          <p className="text-gray-700 mb-6">{professional.summary}</p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="flex items-center text-gray-600">
              <Mail className="w-5 h-5 mr-2" />
              <a 
                href={`mailto:${professional.email}`}
                className="hover:text-blue-600 transition-colors"
              >
                {professional.email}
              </a>
            </div>
            <div className="flex items-center text-gray-600">
              <Phone className="w-5 h-5 mr-2" />
              <a 
                href={`tel:${professional.phone}`}
                className="hover:text-blue-600 transition-colors"
              >
                {professional.phone}
              </a>
            </div>
            <div className="flex items-center text-gray-600">
              <Calendar className="w-5 h-5 mr-2" />
              Available for projects
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}