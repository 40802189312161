import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Search } from 'lucide-react';
import { categories } from '@/data/categories';
import { locations } from '@/data/locations';

export function SearchDialog() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredLocations = locations.filter((location) =>
    location.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
    location.state.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm" className="gap-2">
          <Search className="h-4 w-4" />
          Search
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Search Services & Cities</DialogTitle>
        </DialogHeader>
        <div className="mt-4">
          <Input
            placeholder="Search services or cities..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-4"
            autoFocus
          />
          {searchTerm && (
            <div className="space-y-4">
              {filteredCategories.length > 0 && (
                <div>
                  <h3 className="mb-2 text-sm font-medium">Services</h3>
                  <div className="grid gap-2">
                    {filteredCategories.map((category) => (
                      <Button
                        key={category.id}
                        variant="ghost"
                        className="w-full justify-start"
                        onClick={() => {
                          navigate(`/category/${category.id}`);
                          setOpen(false);
                        }}
                      >
                        {category.name}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
              {filteredLocations.length > 0 && (
                <div>
                  <h3 className="mb-2 text-sm font-medium">Cities</h3>
                  <div className="grid gap-2">
                    {filteredLocations.map((location) => (
                      <Button
                        key={location.id}
                        variant="ghost"
                        className="w-full justify-start"
                        onClick={() => {
                          navigate(`/${location.slug}`);
                          setOpen(false);
                        }}
                      >
                        {location.city}, {location.state}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}