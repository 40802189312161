import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function generateSlug(text: string): string {
  return text
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '');
}

export function formatLocation(city: string, state: string): string {
  return `${city.replace(/-/g, ' ')}, ${state.toUpperCase()}`;
}

export function generateMetaTitle(parts: string[]): string {
  return [...parts, 'FindPros'].join(' - ');
}

export function generateMetaDescription(location: string, service?: string): string {
  if (service) {
    return `Find trusted ${service.toLowerCase()} professionals in ${location}. Get quotes and book services today.`;
  }
  return `Connect with trusted local service professionals in ${location}. Browse by category and find the right pro for your needs.`;
}