import React from 'react';
import { Outlet } from 'react-router-dom';
import { AdminNavigation } from '../components/AdminNavigation';
import { Toaster } from '../components/ui/toaster';

export function AdminLayout() {
  return (
    <div className="flex min-h-screen">
      <AdminNavigation />
      <main className="flex-1 bg-gray-50 p-8">
        <Outlet />
      </main>
      <Toaster />
    </div>
  );
}