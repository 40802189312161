import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const mockData = [
  { date: '2024-01', reviews: 65 },
  { date: '2024-02', reviews: 78 },
  { date: '2024-03', reviews: 92 },
  { date: '2024-04', reviews: 85 },
  { date: '2024-05', reviews: 110 },
  { date: '2024-06', reviews: 125 },
];

export function ReviewsChart() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Review Trends</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={mockData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line 
                type="monotone" 
                dataKey="reviews" 
                stroke="#2563eb" 
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
}