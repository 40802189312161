import { Professional } from '../types';

export const mockProfessionals: Professional[] = [
  {
    id: 'la-electric-1',
    name: 'LA Elite Electric',
    title: 'Master Electrician',
    summary: 'Premier electrical services in Los Angeles with 20+ years of experience. Specializing in residential and commercial electrical installations, repairs, and maintenance.',
    email: 'contact@laeliteelectric.com',
    phone: '(323) 555-0123',
    category: 'electrical',
    mainCategory: 'home-repair',
    rating: 4.9,
    reviewCount: 203,
    description: 'Premier electrical services in Los Angeles with 20+ years of experience.',
    image: 'https://images.unsplash.com/photo-1621905252507-b35492cc74b4?w=800',
    location: { city: 'Los Angeles', state: 'CA' },
    specializations: [
      {
        id: 'residential',
        name: 'Residential Electrical',
        description: 'Complete home electrical services including installations and repairs'
      },
      {
        id: 'commercial',
        name: 'Commercial Electrical',
        description: 'Comprehensive electrical solutions for businesses and commercial properties'
      },
      {
        id: 'emergency',
        name: 'Emergency Services',
        description: '24/7 emergency electrical repair and support'
      }
    ],
    experiences: [
      {
        id: 'exp-1',
        role: 'Master Electrician',
        company: 'LA Elite Electric',
        period: '2010 - Present',
        description: 'Founded and managing premier electrical services company in Los Angeles'
      },
      {
        id: 'exp-2',
        role: 'Senior Electrician',
        company: 'City Power Services',
        period: '2005 - 2010',
        description: 'Led major commercial and residential electrical projects'
      }
    ],
    education: [
      {
        id: 'edu-1',
        degree: 'Electrical Engineering',
        institution: 'UCLA',
        year: '2005'
      },
      {
        id: 'edu-2',
        degree: 'Master Electrician Certification',
        institution: 'California State Board',
        year: '2007'
      }
    ],
    certifications: [
      {
        id: 'cert-1',
        name: 'Master Electrician License',
        issuer: 'State of California',
        year: '2007'
      },
      {
        id: 'cert-2',
        name: 'OSHA Safety Certification',
        issuer: 'OSHA',
        year: '2020'
      }
    ],
    socialMedia: {
      linkedin: 'https://linkedin.com/company/la-elite-electric',
      twitter: 'https://twitter.com/laeliteelectric',
      github: 'https://github.com/laeliteelectric'
    },
    relatedProfessionals: []
  }
];

// Add related professionals reference
mockProfessionals[0].relatedProfessionals = mockProfessionals;