import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const mockData = [
  { month: 'Jan', users: 1200, revenue: 45000, ltv: 320 },
  { month: 'Feb', users: 1350, revenue: 52000, ltv: 340 },
  { month: 'Mar', users: 1500, revenue: 61000, ltv: 355 },
  { month: 'Apr', users: 1750, revenue: 72000, ltv: 375 },
  { month: 'May', users: 2100, revenue: 86000, ltv: 390 },
  { month: 'Jun', users: 2400, revenue: 98000, ltv: 410 },
];

export function GrowthTrends() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Growth Trends</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={mockData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="users"
                stroke="#2563eb"
                name="Users"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="revenue"
                stroke="#16a34a"
                name="Revenue ($)"
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="ltv"
                stroke="#d97706"
                name="LTV"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
}