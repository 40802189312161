export interface Category {
  id: string;
  name: string;
  description: string;
  icon: string;
  subcategories?: Category[];
}

export const categories: Category[] = [
  {
    id: 'home-repair',
    name: 'Home Repair & Maintenance',
    description: 'Essential home repair and maintenance services',
    icon: 'Wrench',
    subcategories: [
      {
        id: 'plumbing',
        name: 'Plumbing',
        description: 'Expert plumbers for all your plumbing needs',
        icon: 'Droplet'
      },
      {
        id: 'electrical',
        name: 'Electrical',
        description: 'Licensed electricians for your electrical work',
        icon: 'Zap'
      },
      {
        id: 'hvac',
        name: 'HVAC',
        description: 'Professional heating and cooling services',
        icon: 'Thermometer'
      },
      {
        id: 'appliance',
        name: 'Appliance Repair',
        description: 'Expert appliance repair and maintenance',
        icon: 'Power'
      },
      {
        id: 'handyman',
        name: 'Handyman',
        description: 'General repairs and home maintenance',
        icon: 'Tool'
      },
      {
        id: 'water-damage',
        name: 'Water Damage',
        description: 'Professional water damage restoration',
        icon: 'Droplets'
      }
    ]
  },
  {
    id: 'construction',
    name: 'Construction & Remodeling',
    description: 'Professional construction and remodeling services',
    icon: 'Building',
    subcategories: [
      {
        id: 'general-contractor',
        name: 'General Contractor',
        description: 'Full-service construction management',
        icon: 'HardHat'
      },
      {
        id: 'carpenter',
        name: 'Carpentry',
        description: 'Skilled carpenters for your woodworking needs',
        icon: 'Hammer'
      },
      {
        id: 'remodeler',
        name: 'Remodeling',
        description: 'Complete home remodeling services',
        icon: 'PenTool'
      },
      {
        id: 'drywall',
        name: 'Drywall',
        description: 'Professional drywall installation and repair',
        icon: 'Square'
      },
      {
        id: 'mason',
        name: 'Masonry',
        description: 'Expert masonry and stonework',
        icon: 'Wall'
      },
      {
        id: 'tile',
        name: 'Tile Installation',
        description: 'Professional tile installation services',
        icon: 'Grid'
      },
      {
        id: 'floor',
        name: 'Floor Installation',
        description: 'Expert flooring installation services',
        icon: 'Square'
      },
      {
        id: 'insulation',
        name: 'Insulation',
        description: 'Professional insulation installation',
        icon: 'Layers'
      }
    ]
  },
  {
    id: 'exterior',
    name: 'Exterior Services',
    description: 'Complete exterior home services',
    icon: 'Home',
    subcategories: [
      {
        id: 'landscaping',
        name: 'Landscaping',
        description: 'Transform your outdoor space',
        icon: 'Trees'
      },
      {
        id: 'roofing',
        name: 'Roofing',
        description: 'Professional roofing services',
        icon: 'Home'
      },
      {
        id: 'painting',
        name: 'Exterior Painting',
        description: 'Professional painting services',
        icon: 'Paintbrush'
      },
      {
        id: 'windows',
        name: 'Window & Door Installation',
        description: 'Expert window and door services',
        icon: 'Square'
      },
      {
        id: 'gutters',
        name: 'Gutter Services',
        description: 'Gutter installation and maintenance',
        icon: 'LineHorizontal'
      },
      {
        id: 'tree',
        name: 'Tree Service',
        description: 'Professional tree care and removal',
        icon: 'Tree'
      },
      {
        id: 'fencing',
        name: 'Fencing',
        description: 'Quality fence installation and repair',
        icon: 'BoxSelect'
      }
    ]
  },
  {
    id: 'installations',
    name: 'Specialty Installations',
    description: 'Expert installation services',
    icon: 'Settings',
    subcategories: [
      {
        id: 'security',
        name: 'Security Systems',
        description: 'Professional security system installation',
        icon: 'Shield'
      },
      {
        id: 'solar',
        name: 'Solar Panels',
        description: 'Solar panel installation services',
        icon: 'Sun'
      },
      {
        id: 'garage',
        name: 'Garage Doors',
        description: 'Garage door installation and repair',
        icon: 'Door'
      }
    ]
  },
  {
    id: 'maintenance',
    name: 'Maintenance & Cleaning',
    description: 'Regular maintenance and cleaning services',
    icon: 'Sparkles',
    subcategories: [
      {
        id: 'cleaning',
        name: 'House Cleaning',
        description: 'Professional house cleaning services',
        icon: 'Brush'
      },
      {
        id: 'pest-control',
        name: 'Pest Control',
        description: 'Effective pest control solutions',
        icon: 'Bug'
      },
      {
        id: 'pool',
        name: 'Pool Maintenance',
        description: 'Complete pool maintenance services',
        icon: 'Waves'
      }
    ]
  }
];