import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Professional } from '../../types';
import { ProfessionalCard } from '../ProfessionalCard';

interface RelatedProfessionalsProps {
  professionals: Professional[];
  currentProfessionalId: string;
}

export function RelatedProfessionals({
  professionals,
  currentProfessionalId,
}: RelatedProfessionalsProps) {
  const filteredProfessionals = professionals
    .filter((pro) => pro.id !== currentProfessionalId)
    .slice(0, 3);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Similar Professionals</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {filteredProfessionals.map((professional) => (
            <ProfessionalCard
              key={professional.id}
              professional={professional}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
}