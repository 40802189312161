import React from 'react';
import { Check } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';

interface Specialization {
  id: string;
  name: string;
  description: string;
}

interface ExpertiseSectionProps {
  specializations: Specialization[];
}

export function ExpertiseSection({ specializations }: ExpertiseSectionProps) {
  return (
    <Card className="mb-8">
      <CardHeader>
        <CardTitle>Areas of Expertise</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {specializations.map((specialization) => (
            <div
              key={specialization.id}
              className="flex items-start p-4 bg-gray-50 rounded-lg"
            >
              <Check className="w-5 h-5 text-green-500 mt-1 mr-3 flex-shrink-0" />
              <div>
                <h3 className="font-medium text-gray-900 mb-1">
                  {specialization.name}
                </h3>
                <p className="text-gray-600 text-sm">
                  {specialization.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}