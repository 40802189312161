import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { Button } from './ui/button';
import { MapPin } from 'lucide-react';
import { locations } from '@/data/locations';

export function CityDialog() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm" className="gap-2">
          <MapPin className="h-4 w-4" />
          Cities
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select a City</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-2 gap-2">
            {locations.map((location) => (
              <Button
                key={location.id}
                variant="ghost"
                className="justify-start"
                onClick={() => {
                  navigate(`/${location.slug}`);
                  setOpen(false);
                }}
              >
                {location.city}, {location.state}
              </Button>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}