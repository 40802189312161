import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { SEOHead } from '../components/SEOHead';
import { CategoryCard } from '../components/CategoryCard';
import { categories } from '../data/categories';
import { locations } from '../data/locations';

export function CityCategories() {
  const { citySlug } = useParams();
  const location = locations.find(loc => 
    loc.slug.toLowerCase() === citySlug?.toLowerCase()
  );

  if (!location) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <SEOHead
        title={`Home Services in ${location.city}, ${location.state}`}
        description={`Find trusted home service professionals in ${location.city}, ${location.state}. Browse our main service categories and connect with qualified experts.`}
      />

      <div className="py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-8">
            Home Services in {location.city}, {location.state}
          </h1>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {categories.map((category) => (
              <CategoryCard
                key={category.id}
                category={category}
                citySlug={citySlug!}
                showSubcategories={true}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}