import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SEOHead } from '../components/SEOHead';
import { HeroSection } from '../components/HeroSection';
import { CategorySection } from '../components/CategorySection';
import { HowItWorksSection } from '../components/HowItWorksSection';
import { BusinessBenefitsSection } from '../components/BusinessBenefitsSection';
import { CityGrid } from '../components/CityGrid';
import { FeaturedProfessionals } from '../components/FeaturedProfessionals';

export function Home() {
  const navigate = useNavigate();

  const handleLocationSelect = (location: string) => {
    const [city, state] = location.split(', ');
    const citySlug = city.toLowerCase().replace(/\s+/g, '-');
    navigate(`/${citySlug}`);
  };

  return (
    <>
      <SEOHead
        title="Find Local Professionals | Home Services Directory"
        description="Connect with trusted local professionals for all your home service needs. Find qualified experts in plumbing, electrical, HVAC, and more. Join our network to grow your business and improve your online presence."
      />
      
      <HeroSection onLocationSelect={handleLocationSelect} />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 space-y-16">
        <section>
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">
            Browse by City
          </h2>
          <CityGrid />
        </section>

        <CategorySection />
        <BusinessBenefitsSection />
        <HowItWorksSection />
        <FeaturedProfessionals />
      </div>
    </>
  );
}