import { Professional } from '../types';
import { mockProfessionals } from '../data/mockProfessionals';

class DataService {
  private professionals: Professional[] = [...mockProfessionals];

  getProfessionals(): Professional[] {
    return this.professionals;
  }

  getProfessionalById(id: string): Professional | undefined {
    return this.professionals.find(p => p.id === id);
  }

  getProfessionalsByCategory(categoryId: string, citySlug: string): Professional[] {
    return this.professionals.filter(p => {
      const professionalCitySlug = p.location.city.toLowerCase().replace(/\s+/g, '-');
      return professionalCitySlug === citySlug.toLowerCase() && 
             (p.category === categoryId || p.mainCategory === categoryId);
    });
  }

  addProfessional(professional: Professional): Professional {
    if (!professional.id) {
      professional.id = `${professional.location.city.toLowerCase().replace(/\s+/g, '-')}-${professional.category}-${Date.now()}`;
    }
    this.professionals.push(professional);
    return professional;
  }

  updateProfessional(id: string, data: Partial<Professional>): Professional | undefined {
    const index = this.professionals.findIndex(p => p.id === id);
    if (index === -1) return undefined;
    
    this.professionals[index] = { ...this.professionals[index], ...data };
    return this.professionals[index];
  }

  deleteProfessional(id: string): boolean {
    const index = this.professionals.findIndex(p => p.id === id);
    if (index === -1) return false;
    
    this.professionals.splice(index, 1);
    return true;
  }
}

// Export a singleton instance
export const dataService = new DataService();