import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { Button } from './ui/button';
import { Search } from 'lucide-react';
import { Input } from './ui/input';
import { locations } from '../data/locations';

export function LocationSearch() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const filteredLocations = locations.filter(location =>
    `${location.city}, ${location.state}`.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="w-full justify-start text-left font-normal">
          <Search className="mr-2 h-4 w-4" />
          Search locations...
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Find Your Location</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Input
            placeholder="Search cities..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="col-span-3"
          />
          <div className="max-h-[300px] overflow-y-auto">
            {filteredLocations.map((location) => (
              <Button
                key={location.id}
                variant="ghost"
                className="w-full justify-start"
                onClick={() => {
                  navigate(`/${location.slug}`);
                  setOpen(false);
                }}
              >
                {location.city}, {location.state}
              </Button>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}