import React from 'react';
import { ProfessionalCard } from './ProfessionalCard';
import { mockProfessionals } from '../data/mockProfessionals';

export function FeaturedProfessionals() {
  const featuredPros = mockProfessionals.slice(0, 3);

  return (
    <section className="py-16">
      <div className="mb-12">
        <h2 className="text-3xl font-bold text-gray-900 text-center">
          Featured Professionals
        </h2>
        <p className="mt-4 text-lg text-gray-600 text-center max-w-2xl mx-auto">
          Meet our top-rated service providers who consistently deliver exceptional results
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {featuredPros.map((professional) => (
          <ProfessionalCard
            key={professional.id}
            professional={professional}
            onClick={() => {/* Handle click */}}
          />
        ))}
      </div>
    </section>
  );
}