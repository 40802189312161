import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, LayoutDashboard, Users, MessageSquare, BarChart, Settings } from 'lucide-react';

export function AdminNavigation() {
  const location = useLocation();

  const navItems = [
    { path: '/admin', icon: LayoutDashboard, label: 'Dashboard' },
    { path: '/admin/users', icon: Users, label: 'Users' },
    { path: '/admin/reviews', icon: MessageSquare, label: 'Reviews' },
    { path: '/admin/analytics', icon: BarChart, label: 'Analytics' },
    { path: '/admin/settings', icon: Settings, label: 'Settings' },
  ];

  return (
    <nav className="bg-gray-900 text-gray-200 w-64 min-h-screen p-4">
      <div className="mb-8">
        <Link to="/" className="flex items-center text-white hover:text-blue-400 transition-colors">
          <Home className="h-6 w-6" />
          <span className="ml-2 text-xl font-semibold">Local Knows</span>
        </Link>
      </div>

      <div className="space-y-2">
        {navItems.map((item) => (
          <Link
            key={item.path}
            to={item.path}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
              location.pathname === item.path
                ? 'bg-blue-600 text-white'
                : 'hover:bg-gray-800'
            }`}
          >
            <item.icon className="h-5 w-5 mr-3" />
            {item.label}
          </Link>
        ))}
      </div>
    </nav>
  );
}