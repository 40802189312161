import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { Textarea } from '../ui/textarea';
import { useToast } from '../ui/use-toast';
import { categories } from '../../data/categories';
import { locations } from '../../data/locations';
import { dataService } from '../../services/dataService';
import { Loader2 } from 'lucide-react';

export function BusinessRegistrationForm() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.currentTarget);
    const businessData = {
      id: `${formData.get('city')?.toString().toLowerCase().replace(/\s+/g, '-')}-${formData.get('name')?.toString().toLowerCase().replace(/\s+/g, '-')}`,
      name: formData.get('name')?.toString() || '',
      title: formData.get('title')?.toString() || '',
      summary: formData.get('summary')?.toString() || '',
      email: formData.get('email')?.toString() || '',
      phone: formData.get('phone')?.toString() || '',
      category: formData.get('category')?.toString() || '',
      mainCategory: formData.get('mainCategory')?.toString() || '',
      description: formData.get('description')?.toString() || '',
      location: {
        city: formData.get('city')?.toString() || '',
        state: formData.get('state')?.toString() || ''
      },
      rating: 0,
      reviewCount: 0,
      image: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=800',
      specializations: [],
      experiences: [],
      education: [],
      certifications: [],
      portfolio: [],
      services: [],
      availability: {
        schedule: [],
        nextAvailable: new Date().toISOString()
      },
      socialMedia: {},
      relatedProfessionals: []
    };

    try {
      await dataService.addProfessional(businessData);
      
      toast({
        title: 'Success',
        description: 'Business registered successfully!',
      });
      
      // Navigate to the new business profile
      navigate(`/${businessData.location.city.toLowerCase().replace(/\s+/g, '-')}/${businessData.mainCategory}/${businessData.id}`);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to register business. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Business Registration</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="text-sm font-medium" htmlFor="name">
                Business Name
              </label>
              <Input id="name" name="name" required />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium" htmlFor="title">
                Business Title/Tagline
              </label>
              <Input id="title" name="title" required />
            </div>
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium" htmlFor="summary">
              Business Summary
            </label>
            <Textarea
              id="summary"
              name="summary"
              placeholder="Brief overview of your business"
              required
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="text-sm font-medium" htmlFor="email">
                Business Email
              </label>
              <Input id="email" name="email" type="email" required />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium" htmlFor="phone">
                Business Phone
              </label>
              <Input id="phone" name="phone" type="tel" required />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="text-sm font-medium" htmlFor="city">
                City
              </label>
              <select
                id="city"
                name="city"
                className="w-full rounded-md border border-gray-300 p-2"
                required
              >
                <option value="">Select a city</option>
                {locations.map((location) => (
                  <option key={location.id} value={location.city}>
                    {location.city}, {location.state}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium" htmlFor="state">
                State
              </label>
              <Input id="state" name="state" required />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="text-sm font-medium" htmlFor="mainCategory">
                Main Category
              </label>
              <select
                id="mainCategory"
                name="mainCategory"
                className="w-full rounded-md border border-gray-300 p-2"
                required
              >
                <option value="">Select main category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium" htmlFor="category">
                Specific Category
              </label>
              <select
                id="category"
                name="category"
                className="w-full rounded-md border border-gray-300 p-2"
                required
              >
                <option value="">Select specific category</option>
                {categories.flatMap((category) =>
                  category.subcategories?.map((sub) => (
                    <option key={sub.id} value={sub.id}>
                      {sub.name}
                    </option>
                  ))
                )}
              </select>
            </div>
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium" htmlFor="description">
              Detailed Description
            </label>
            <Textarea
              id="description"
              name="description"
              placeholder="Detailed description of your services and expertise"
              className="h-32"
              required
            />
          </div>

          <Button type="submit" className="w-full" disabled={loading}>
            {loading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Registering...
              </>
            ) : (
              'Register Business'
            )}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}