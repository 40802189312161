import React from 'react';
import { CategoryCard } from './CategoryCard';
import { categories } from '../data/categories';

export function CategorySection() {
  return (
    <section className="py-16 bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto mb-12">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
            Professional Services
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Browse our comprehensive range of home service categories
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {categories.map((category) => (
            <CategoryCard 
              key={category.id} 
              category={category}
              showSubcategories={true}
            />
          ))}
        </div>
      </div>
    </section>
  );
}