import React from 'react';
import { useParams } from 'react-router-dom';
import { SEOHead } from '../components/SEOHead';
import { ProfileHeader } from '../components/professional/ProfileHeader';
import { ExpertiseSection } from '../components/professional/ExpertiseSection';
import { ExperienceTimeline } from '../components/professional/ExperienceTimeline';
import { EducationSection } from '../components/professional/EducationSection';
import { ConsultationForm } from '../components/professional/ConsultationForm';
import { RelatedProfessionals } from '../components/professional/RelatedProfessionals';
import { useProfessionalDetails } from '../hooks/useProfessionalDetails';
import { Loader2 } from 'lucide-react';

export function ProfessionalProfile() {
  const { professionalId } = useParams();
  const { professional, loading, error } = useProfessionalDetails(professionalId);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
      </div>
    );
  }

  if (error || !professional) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Professional not found
        </h2>
        <p className="text-gray-600">
          The professional you're looking for might have been removed or is temporarily unavailable.
        </p>
      </div>
    );
  }

  return (
    <>
      <SEOHead
        title={`${professional.name} - Professional Profile`}
        description={`Learn more about ${professional.name}, a professional ${professional.category} in ${professional.location.city}. View expertise, experience, and book a consultation.`}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <ProfileHeader professional={professional} />

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 space-y-8">
            <ExpertiseSection specializations={professional.specializations} />
            <ExperienceTimeline experiences={professional.experiences} />
            <EducationSection
              education={professional.education}
              certifications={professional.certifications}
            />
          </div>

          <div className="space-y-8">
            <ConsultationForm />
          </div>
        </div>

        <div className="mt-12">
          <RelatedProfessionals
            professionals={professional.relatedProfessionals}
            currentProfessionalId={professional.id}
          />
        </div>
      </div>
    </>
  );
}