import React from 'react';
import { Link } from 'react-router-dom';
import * as Icons from 'lucide-react';
import type { Category } from '../data/categories';
import { Card, CardHeader, CardContent } from './ui/card';

interface CategoryCardProps {
  category: Category;
  citySlug: string;
  showSubcategories?: boolean;
}

export function CategoryCard({ 
  category, 
  citySlug, 
  showSubcategories = false 
}: CategoryCardProps) {
  const IconComponent = Icons[category.icon as keyof typeof Icons];
  
  return (
    <Link to={`/${citySlug}/${category.id}`} className="block">
      <Card className="group hover:shadow-lg transition-all duration-300 overflow-hidden h-full">
        <CardHeader className="relative pb-0">
          <div className="absolute -right-4 -top-4 w-20 h-20 bg-blue-500/10 rounded-full group-hover:scale-150 transition-transform duration-500" />
          {IconComponent && (
            <div className="relative z-10">
              <IconComponent className="w-8 h-8 text-blue-600 group-hover:scale-110 transition-transform duration-300" />
            </div>
          )}
        </CardHeader>
        <CardContent>
          <h3 className="text-xl font-semibold text-gray-900 mb-2 group-hover:text-blue-600 transition-colors">
            {category.name}
          </h3>
          <p className="text-gray-600 text-sm mb-4">
            {category.description}
          </p>
          {showSubcategories && category.subcategories && (
            <div className="space-y-2">
              {category.subcategories.map((sub) => (
                <div key={sub.id} className="text-sm text-gray-500 flex items-center">
                  <div className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2" />
                  {sub.name}
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    </Link>
  );
}