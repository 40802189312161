import api from '../lib/api';

export interface AdminSettings {
  siteName: string;
  supportEmail: string;
  maintenanceMode: boolean;
  smtp: {
    host: string;
    port: number;
    username: string;
    password: string;
  };
}

const defaultSettings: AdminSettings = {
  siteName: 'Local Knows',
  supportEmail: 'support@localknows.info',
  maintenanceMode: false,
  smtp: {
    host: '',
    port: 587,
    username: '',
    password: ''
  }
};

class SettingsService {
  private settings: AdminSettings = defaultSettings;

  async getSettings(): Promise<AdminSettings> {
    try {
      const response = await api.get('/admin/settings');
      this.settings = response.data;
      return this.settings;
    } catch (error) {
      console.error('Failed to fetch settings:', error);
      return this.settings;
    }
  }

  async updateSettings(newSettings: Partial<AdminSettings>): Promise<AdminSettings> {
    try {
      const response = await api.patch('/admin/settings', newSettings);
      this.settings = { ...this.settings, ...response.data };
      return this.settings;
    } catch (error) {
      console.error('Failed to update settings:', error);
      throw new Error('Failed to update settings');
    }
  }
}

// Export a singleton instance
export const settingsService = new SettingsService();