import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card';
import { Star } from 'lucide-react';

const mockData = [
  { rating: 5, count: 450, percentage: 45 },
  { rating: 4, count: 320, percentage: 32 },
  { rating: 3, count: 150, percentage: 15 },
  { rating: 2, count: 50, percentage: 5 },
  { rating: 1, count: 30, percentage: 3 },
];

export function RatingDistribution() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Rating Distribution</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {mockData.map(({ rating, count, percentage }) => (
            <div key={rating} className="space-y-1">
              <div className="flex items-center gap-2">
                <span className="text-sm font-medium w-8">
                  {rating} <Star className="inline-block w-4 h-4 text-yellow-400" />
                </span>
                <div className="flex-1 h-4 bg-gray-100 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-yellow-400 transition-all"
                    style={{ width: `${percentage}%` }}
                  />
                </div>
                <span className="text-sm text-gray-600 w-16">
                  {count} ({percentage}%)
                </span>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}