import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, Search, MapPin, Grid2X2, Phone } from 'lucide-react';
import { SearchDialog } from './SearchDialog';
import { CityDialog } from './CityDialog';
import { CategoryDialog } from './CategoryDialog';
import { ContactDialog } from './ContactDialog';

export function Navigation() {
  const location = useLocation();
  const isAdmin = location.pathname.startsWith('/admin');

  if (isAdmin) return null;

  return (
    <nav className="sticky top-0 z-50 bg-white/80 backdrop-blur-md border-b">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          {/* Left section */}
          <div className="flex items-center">
            <Link 
              to="/" 
              className="flex items-center hover:text-blue-600 transition-colors"
            >
              <Home className="h-6 w-6 text-blue-600" />
              <span className="ml-2 text-xl font-semibold">Local Knows</span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-4">
            <SearchDialog />
            <CityDialog />
            <CategoryDialog />
            <ContactDialog />
            <Link 
              to="/register-business"
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
            >
              List Your Business
            </Link>
          </nav>
        </div>
      </div>
    </nav>
  );
}