import React from 'react';
import { Search, UserCheck, Calendar, Star, Briefcase, Users, MessageSquare, CheckCircle } from 'lucide-react';

interface Step {
  icon: React.ElementType;
  title: string;
  description: string;
}

const customerSteps: Step[] = [
  {
    icon: Search,
    title: "Search Local Professionals",
    description: "Browse through our extensive network of verified service providers in your area"
  },
  {
    icon: UserCheck,
    title: "Compare & Choose",
    description: "Review ratings, portfolios, and credentials to find the perfect match for your needs"
  },
  {
    icon: Calendar,
    title: "Book Services",
    description: "Schedule appointments directly through our platform at your convenience"
  },
  {
    icon: Star,
    title: "Leave Feedback",
    description: "Share your experience and help others make informed decisions"
  }
];

const professionalSteps: Step[] = [
  {
    icon: Briefcase,
    title: "Create Your Profile",
    description: "Showcase your expertise, services, and portfolio to potential clients"
  },
  {
    icon: Users,
    title: "Connect with Clients",
    description: "Receive booking requests and grow your customer base"
  },
  {
    icon: MessageSquare,
    title: "Manage Communications",
    description: "Handle inquiries and bookings through our streamlined platform"
  },
  {
    icon: CheckCircle,
    title: "Build Your Reputation",
    description: "Collect reviews and build trust with quality service"
  }
];

export function HowItWorksSection() {
  const [activeTab, setActiveTab] = React.useState<'customers' | 'professionals'>('customers');

  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">How It Works</h2>
          <p className="mt-4 text-xl text-gray-600">
            Simple steps to connect service providers with customers
          </p>
        </div>

        {/* Tab Buttons */}
        <div className="flex justify-center mb-12">
          <div className="inline-flex rounded-lg border border-gray-200 p-1 bg-white">
            <button
              onClick={() => setActiveTab('customers')}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                activeTab === 'customers'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              For Customers
            </button>
            <button
              onClick={() => setActiveTab('professionals')}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                activeTab === 'professionals'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              For Professionals
            </button>
          </div>
        </div>

        {/* Steps Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {(activeTab === 'customers' ? customerSteps : professionalSteps).map((step, index) => (
            <div
              key={step.title}
              className="relative bg-white p-6 rounded-lg shadow-md"
            >
              {/* Step Number */}
              <div className="absolute -top-4 left-6 w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center text-sm font-bold">
                {index + 1}
              </div>

              {/* Icon */}
              <div className="mb-4 text-blue-600">
                <step.icon className="w-8 h-8" />
              </div>

              {/* Content */}
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {step.title}
              </h3>
              <p className="text-gray-600">
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}