import React from 'react';
import { SEOHead } from '../components/SEOHead';
import { BusinessRegistrationForm } from '../components/business/BusinessRegistrationForm';

export function BusinessRegistration() {
  return (
    <>
      <SEOHead
        title="Register Your Business"
        description="Join our network of trusted professionals. Register your business and start connecting with customers today."
      />

      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          Register Your Business
        </h1>
        <BusinessRegistrationForm />
      </div>
    </>
  );
}