import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { SEOHead } from '../components/SEOHead';
import { ProfessionalCard } from '../components/ProfessionalCard';
import { categories } from '../data/categories';
import { locations } from '../data/locations';
import { mockProfessionals } from '../data/mockProfessionals';
import { Loader2 } from 'lucide-react';

export function CategoryProfessionals() {
  const { citySlug, mainCategoryId } = useParams();
  const [loading, setLoading] = React.useState(true);

  const location = locations.find(loc => 
    loc.slug.toLowerCase() === citySlug?.toLowerCase()
  );
  const mainCategory = categories.find(c => c.id === mainCategoryId);

  React.useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  if (!location || !mainCategory) {
    return <Navigate to="/" replace />;
  }

  // Get all professionals for this main category and its subcategories
  const professionals = mockProfessionals.filter(
    pro => 
      pro.location.city.toLowerCase() === location.city.toLowerCase() &&
      pro.mainCategory === mainCategoryId
  );

  return (
    <>
      <SEOHead
        title={`${mainCategory.name} Services in ${location.city}, ${location.state}`}
        description={`Find trusted ${mainCategory.name.toLowerCase()} professionals in ${location.city}, ${location.state}. Get quotes and book services today.`}
      />

      <div className="py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-8">
            {mainCategory.name} Services in {location.city}, {location.state}
          </h1>

          {loading ? (
            <div className="flex justify-center items-center py-12">
              <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
            </div>
          ) : professionals.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-600">No professionals found in this category.</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {professionals.map((professional) => (
                <ProfessionalCard
                  key={professional.id}
                  professional={professional}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}