import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { Button } from '../ui/button';
import { Check, X, Star } from 'lucide-react';
import { Badge } from '../ui/badge';

interface ReviewTableProps {
  onApproveReview: (reviewId: string) => Promise<void>;
  onRejectReview: (reviewId: string) => Promise<void>;
}

export function ReviewTable({
  onApproveReview,
  onRejectReview,
}: ReviewTableProps) {
  const mockReviews = [
    {
      id: '1',
      author: 'Jane Smith',
      rating: 4,
      content: 'Great service, very professional!',
      status: 'pending',
      createdAt: '2024-03-10',
    },
    // Add more mock reviews as needed
  ];

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Author</TableHead>
          <TableHead>Rating</TableHead>
          <TableHead>Review</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Date</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {mockReviews.map((review) => (
          <TableRow key={review.id}>
            <TableCell>{review.author}</TableCell>
            <TableCell>
              <div className="flex items-center">
                {review.rating}
                <Star className="w-4 h-4 text-yellow-400 ml-1" />
              </div>
            </TableCell>
            <TableCell className="max-w-md truncate">
              {review.content}
            </TableCell>
            <TableCell>
              <Badge
                variant={
                  review.status === 'approved'
                    ? 'default'
                    : review.status === 'rejected'
                    ? 'destructive'
                    : 'secondary'
                }
              >
                {review.status}
              </Badge>
            </TableCell>
            <TableCell>{new Date(review.createdAt).toLocaleDateString()}</TableCell>
            <TableCell>
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => onApproveReview(review.id)}
                >
                  <Check className="w-4 h-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => onRejectReview(review.id)}
                >
                  <X className="w-4 h-4" />
                </Button>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}