import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Star } from 'lucide-react';
import { Professional } from '../types';

interface ProfessionalCardProps {
  professional: Professional;
}

export function ProfessionalCard({ professional }: ProfessionalCardProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    const citySlug = professional.location.city.toLowerCase().replace(/\s+/g, '-');
    navigate(`/${citySlug}/${professional.mainCategory}/${professional.id}`);
  };

  return (
    <div 
      onClick={handleClick}
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow duration-200"
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          handleClick();
        }
      }}
    >
      <img 
        src={professional.image} 
        alt={professional.name}
        className="w-full h-48 object-cover"
      />
      <div className="p-4">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">
          {professional.name}
        </h3>
        <div className="flex items-center mb-2">
          <Star className="w-5 h-5 text-yellow-400 fill-current" />
          <span className="ml-1 text-gray-700">
            {professional.rating} ({professional.reviewCount} reviews)
          </span>
        </div>
        <p className="text-gray-600 text-sm mb-4">
          {professional.description}
        </p>
        <div className="text-sm text-gray-500">
          {professional.location.city}, {professional.location.state}
        </div>
      </div>
    </div>
  );
}